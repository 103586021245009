import React, { useRef } from "react"

import { HeroContent } from "../HeroContent"
import { Banner } from "../_types/hero-types"
import { useHeroComponents } from "../_hooks/useHeroComponents"
import {
  GenerateVariationPrefixes,
  HeroContentBaseClassName,
} from "../_types/hero-classes-types"
import { useHeroForm } from "../_hooks/useHeroForm"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { HeroForm } from "components/forms/HeroForm"

import "../HeroBanner/hero.module.scss"
import "./ppc-hero.module.scss"

interface HeroProps {
  banner: Banner
}

export const PpcHero = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    components,
    heading,
    sub_heading,
    tagline,
  } = banner

  const { form, hasForm } = useHeroComponents(components?.modular_content)

  const prefix: GenerateVariationPrefixes = "fr-hero",
    prefixModifier = hasForm ? `${prefix}--with-form` : "",
    backgroundImageUrl = background_image?.value[0]?.url,
    bannerContentBaseClassName =
      `${prefix}__banner-content` as HeroContentBaseClassName,
    contentClassName = hasForm
      ? (`${bannerContentBaseClassName}--with-form` as HeroContentBaseClassName)
      : (`${bannerContentBaseClassName}` as HeroContentBaseClassName)

  const formContainerRef = useRef<HTMLDivElement>(null),
    heroContainerRef = useRef<HTMLDivElement>(null)

  const { style } = useHeroForm({
    formHeight: formContainerRef.current?.clientHeight,
    heroHeight: heroContainerRef.current?.clientHeight,
  })

  return (
    <div className={hasForm ? "fr-hero-container" : ""} style={style}>
      <BannerWrapper
        backgroundImageUrl={backgroundImageUrl}
        containerSize="large"
        contentClassName={contentClassName}
        prefix={prefix}
        prefixModifierClassName={prefixModifier}
        containerRef={heroContainerRef}
      >
        <HeroContent
          baseClassName={prefix}
          buttons={buttons?.value}
          hasAlwaysOfficeNumber
          heading={heading?.value}
          subHeading={sub_heading}
          tagline={tagline}
        />
      </BannerWrapper>
      {hasForm && (
        <HeroForm
          containerSize="large"
          form={form}
          prefix={`${prefix}-form`}
          containerRef={formContainerRef}
        />
      )}
    </div>
  )
}
