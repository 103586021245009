import React, { useEffect, useState, ReactNode } from "react"
import { Helmet } from "react-helmet"

import { setTrackingCookies } from "assets/helper/setTrackingCookies"
import { appendScript, appendInlineScript } from "../assets/helper/appendScript"
import { useFormFields } from "hooks/useFormFields"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useLogos } from "hooks/queries/settings/useLogos"
import { useNavigationItems } from "hooks/useNavigationItems"
import { useWindowDimensions } from "hooks/useWindowDimensions"

import { FormModal } from "./modal/FormModal/FormModal"
import { Footer } from "./footer/Footer"
import { Header } from "./main-header/Header"
import { LayoutContext } from "contexts/components/LayoutContext"
import { TrackingButton } from "components/tracking-button"
import { TrackingNumberModalContainer } from "./modal/TrackingNumberModal/TrackingNumberModalContainer"
import { TrackingNumberModalProvider } from "contexts/TrackingNumberModalContext"

import "assets/scss/4-templates/landing-page-template.module.scss"

interface LandingPageLayoutProps {
  children: ReactNode
  customTrackingNumber?: string
  location: string
  pathName: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
}

export const LandingPageLayout = ({
  children,
  customTrackingNumber,
  linkedPagesUrlPathCodex,
  location,
  mobilePreload,
  parentSlug,
  pathName,
}: LandingPageLayoutProps) => {
  const { size, height } = useWindowDimensions()
  const slug =
    location?.startsWith("/") && location !== "/"
      ? location.substring(1)
      : "home"

  let scrollClass = ""

  if (height > 64) scrollClass = "page-content--scroll-version"

  const { customCSSUrl, scripts } = useGeneralSettings()
  const { footerNavigation, creditsNavigation } = useNavigationItems()
  const { footerLogo, headerLogo } = useLogos()
  const { isFormModalOpen } = useFormFields()

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" &&
          appendScript({
            id: `globalScript-${index + 1}`,
            scriptToAppend: script.elements.src_value?.value || "",
            isAsync: true,
          })

        script.elements.script?.value !== "" &&
          appendInlineScript({
            id: `globalScriptInline-${index + 1}`,
            scriptToAppend: script.elements.script?.value || "",
            isAsync: false,
          })
      })
    }
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        breadcrumbLocation: pathName,
        customTrackingNumber,
        linkedPagesUrlPathCodex,
        parentSlug,
        slug: location,
      }}
    >
      <div className={`wrapper wrapper--${slug === "/" ? "home" : slug}`}>
        <Helmet>
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && size < 768 && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>

        <TrackingNumberModalProvider>
          <TrackingNumberModalContainer>
            <Header isLandingPage hasToggleMenu={false} logo={headerLogo}>
              <TrackingButton />
            </Header>
          </TrackingNumberModalContainer>
        </TrackingNumberModalProvider>

        {isFormModalOpen && <FormModal />}

        <main className={`page-content--landing-page ${scrollClass}`}>
          {children}
        </main>

        <Footer
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
          logo={footerLogo}
        />
      </div>
    </LayoutContext.Provider>
  )
}
